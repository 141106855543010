







































































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

[showBorders1] .LuMobileHeader {
	outline: 1px solid red;
	background-color: fade(red, 20);
}
[showBorders1] .LuMobileHeader__bar > * {
	outline: 1px solid fade( green, 20 );
	background-color: fade( green, 20 );
}
[showBorders1] .LuMobileHeader__logo {
	background-color: fade(red, 20);
}

@headerHeight : 6rem;

.LuMobileHeader {
	position: relative;
	height: @headerHeight;
	display: flex;
	//padding-bottom: 1rem;
	z-index: 100;

	&__barInner {
		position: absolute;
		top: 0; left: 0; right: 0;
		padding-top: 0.75rem;
		padding-bottom: 1rem;
		height: @headerHeight;
		//display: flex;
		//flex-grow: 1;
		//justify-content: space-between;

		display: grid;
		grid-column-gap: @colGap;
		grid-template-columns: repeat(3, 1fr);
	}
	&__barInner::after { // border below
		position: absolute;
		bottom: 0; right: 0; left: 0;
		height: 1px;
		content: "";

		background-color: currentColor;
		opacity: 0.5;
	}

	&__buttons {
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		transform: translateY( 6% );
	}
	&__button {
		//background-color: fade( red, 20 );

		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 0.4rem;
		color: currentColor;
		opacity: 1 !important;
	}
	&__button--toggleLang {}
	&__button--toggleMenu {}
	&__buttonDot {
		font-size: 0.75em;
		opacity: 0.85;
	}

	&__logo {
		position: relative;
		width: 100%;
		overflow: hidden; // for negative text indent
		text-indent: -100vw;
		//z-index: 5; // show the logo if the menu list is open
	}
	&__logo::after { // fullspan image as mask to change color depending on font color
		position: absolute;
		top: 5px; left: 2px; right: 0; bottom: 5px;
		content: "";

		background-color: @swatches[awardColor];
		background-color: currentColor;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		mask: url(./../assets/imgs/lubokLogo--thick.svg) no-repeat 0% 50%;
		mask-size: contain;
		pointer-events: none;
	}
	&__logoHomeLink {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
	}

}
.LuMobileHeader__menuWrapper {
	//background-color: lighten(red, 27);
	//color: red;

	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	display: flex;
	flex-direction: column;
	background-color: inherit;
	transition: inherit;
	z-index: 2;

	&Expander {
		flex-grow: 1;
	}

	.LuMobileHeader__button--toggleMenu {
		//background-color: fade( red, 20 );
		position: relative;

		span:nth-child(1) {
			color: transparent;
		}
		span:nth-child(2),
		span:nth-child(3) {
			position: absolute;
			top: 50%; left: 50%;
			height: 2px;
			background-color: currentColor;
			width: 2rem;
		}
		span:nth-child(2) {
			transform: translateX(-50%) rotate(45deg);
		}
		span:nth-child(3) {
			transform: translateX(-50%) rotate(-45deg);
		}
	}

}
.LuMobileHeader__menuWrapper--isInactive {
	//transition: all 0.65s ease;
	//background-color: fade( red, 20 );
	transition-timing-function: ease-in;
	transition-duration: 0s;
	transform: translateX(120%); // lieber etwas mehr

	/*
	.LuMainMenu__li:nth-child(1) { transform: translateY( 100% ); }
	.LuMainMenu__li:nth-child(2) { transform: translateY( 200% ); }
	.LuMainMenu__li:nth-child(3) { transform: translateY( 300% ); }
	.LuMainMenu__li:nth-child(4) { transform: translateY( 400% ); }
	.LuMainMenu__li:nth-child(5) { transform: translateY( 500% ); }
	.LuMainMenu__li 			 { transform: translateY( 600% ); }
	*/

	.LuMainMenu__ul {}
	.LuMainMenu__li {
		//opacity: 0;
	}
}
.LuMobileHeader__menuWrapper--isActive {
	//transition: all 0.15s ease;
	transition-timing-function: ease-out;
	transition-duration: 0s;
	transform: translateY(0%);

	.LuMobileHeader__buttonDot {
		opacity: 0;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
