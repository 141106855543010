



















































































































































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuGallery {
	position: relative;

	.vueperSlides {

		@ui :{
			transition-duration: 0.2s;
		}
		@arrow : {
			height: 2em;
			padding: 1em;
			offset: 0.75em; // pfeil zum rand verschieben
		}
		@bullet : {
			height: 0.6rem;
			padding: 1em 0.3em; // this defines the height of the bullets bars
			opacity: 0.2;
			activeOpacity: 0.65;
		}

		// images werden full span
		.mhImage,
		//.mhImage--mode-cover,
		//.mhImage--mode-contain
		{
			position: absolute;
			top: 0; bottom: 0;
			left: 0; right: 0;
		}

		// general slider ui styling
		&__arrows {}
		&__arrow {
			transition: opacity @ui[transition-duration] ease;

			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			padding: @arrow[padding];
			width: auto;
			background-color: transparent;
			border: none;
			opacity: 0;

			svg {
				display: block;
				height: @arrow[height];
				width: @arrow[height];

				path {
					stroke: currentColor;
					stroke-width: 1px
				}
			}

			.vueperSlides:hover & {
				opacity: 0.5;
			}
			&:hover {
				opacity: 1 !important;
			}

			&--prev {
				left: 0;

				svg {
					margin-left: -@arrow[offset];
				}
			}
			&--next {
				right: 0;

				svg {
					margin-right: -@arrow[offset];
				}

			}
		}
		&__bullets {
			//background-color: red;
		}
		&__bullet {
			padding: @bullet[padding];
			background-color: transparent;
			border: none;

			span {
				transition: opacity @ui[transition-duration] ease;
				overflow: hidden;
				display: inline-block;
				background-color: currentColor;
				text-indent: -100em;
				width: @bullet[height];
				height: @bullet[height];
				border-radius: 50%;
				opacity: @bullet[opacity];
				cursor: inherit;
			}
			&--active span {
				opacity: @bullet[activeOpacity];
			}
			&:hover span {
				opacity: 0.75;
			}
		}


		// context specific
		&--asContentModule {
			.vueperSlides__bullets {
				color: @swatches[awardColor];
			}
		}
		&--asProductSlider {
			// um den schlagschatten des covers sichtbar zu machen,
			// habe ich die overflow eigenschaften angepasst
			overflow: hidden;
			.vueperSlides__parallax-wrapper,
			.vueperSlides__track {
				overflow: visible;
			}

			// das cover wird etwas kleiner dargestellt,
			// damit es nicht gedrängt wirkt und der schatten
			// zur geltung kommt
			.vueperSlide:first-child .mhImage{
				bottom: 1em;
			}

			// für den platz nach unten und das es raum für
			// den cover-schatten gibt. dieser würde ansonsten vom
			// overflow abgeschnitten. das ist allerdings nur
			// notwendig wenn es nur ein bild gibt. Dann erscheinen
			// nämlich die bullets nicht (diese würde ansonsten
			// das abschneiden des schattens verhindern)
			&.vueperSlides--slidesCount-1 {
				padding-bottom: 1.5rem;
			}

		}
	}
	.vueperSlide {
		&__title {
			display: none
		}
	}
}
.LuGallery__captionHolder {
	margin-top: -0.25em;
	opacity: 0.5;
	//padding-top: 1.25em;

	// if there is just one image then there are no bullets
	// for the spacing thats why we some spacing here
	.vueperSlides--slidesCount-1 + & {
		padding-top: 1.25em;
	}
}
.LuGallery__bullets {}



@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
