














































































































































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

.LuMainHeader {
	position: relative;
	display: flex;
	flex-direction: column;
	line-height: 1em;

	&__inner {
		padding: 0.7rem;
		padding-bottom: 1rem;

		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between;

		//transition: color 0.1s ease; // smooth change of the vibrantColor for products
	}
	&__inner::after { // border right
		@gap : 0.25rem;
		@color : fade( black, 30 );

		position: absolute;
		top: @gap; bottom: @gap;
		right: -1px;
		width: 1px;
		background-color: currentColor;
		content: "";
	}

	&__logo {
		position: relative;
		display: flex;

		border: 1em solid transparent;
		border-left-width: 2.5em;
		border-right-width: 2.5em;
		border-top-width: 1em;

		//font-size: 0em;
		//line-height: 0;

		//background-image: url(./assets/imgs/lubokLogo.svg);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		//background-color: currentColor;
		//mask: url(./assets/imgs/lubokLogo.svg) no-repeat 50% 50%;
	}
	&__logoHomeLink {
		//position: absolute;
		//top: 0; left: 0; right: 0; bottom: 0;
		overflow: hidden;
		flex-grow: 1;
		padding-bottom: 60%;

		color: inherit;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-color: currentColor;
		mask: url(./../assets/imgs/lubokLogo.svg) no-repeat 50% 50%;
		mask-size: contain;

		text-indent: -1000em;
	}

	&__row {
		display: flex;
	}
	&__li {
		list-style: none;
		display: flex;
	}
	&__footerLinks {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	&__a {
		position: relative;
		display: flex;
		flex-grow: 1;
		text-decoration: none;
		align-items: center;
		color: inherit;

		// indent second level links
		&--second {
			padding-left: 1em;
		}

		// active indicator
		&--first::before {
			//background-color: fade(red, 60);
			transition: all 0.4s ease;

			position: absolute;
			left: -1em;
			//transform: scale(0);
			transform-origin: center;

			//opacity: 0;
			font-size: 1.25em;
			content: "•";
		}
		&--first.routerLink--exactActive::before {
			left: -0.7em;
			//opacity: 1;
		}
		&--second::before {
			transition: all 0.35s ease;

			position: absolute;
			left: 0;
			left: -2em;
			//transform: scale(0);
			transform-origin: center;

			//opacity: 0;
			font-size: 1.25em;
			content: "•";
		}
		&--second.routerLink--exactActive::before {
			//transform: scale(1);
			left: 0;
			//opacity: 1;
		}
	}
	&__a--footerLink {
		flex-grow: 0;
	}
	&__countProducts {	// hochgestellter counter hinter den filtern unter shop
		display: inline-block;
		padding-left: .5em;
		transform: translateY(-.25em);

		font-size: .7em;
		line-height: 1em;
		font-weight: 400;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {
	.LuMainHeader {
		.vSpace--page;
		.border--strong;
		filter: invert(100%);

		overflow: hidden;
		background-color: fade( red, 15 ) !important;
		//background-color: ;

		&__inner {
			//background-color: inherit !important;
			//background-color: none !important;
			padding: 0;
		}
		&__inner::after { // border aside
			.background--productTeaser !important;

			@offset: -1em;

			top: @offset; left: @offset; right: @offset; bottom: @offset;
			width: auto;
			border: none;
		}

		&__logo {
			color: white;
			z-index: 1;
			border-left-width: 1.5em;
			border-right-width: 1.5em;
			border-top-width: 0em;
			border-bottom-width: 0em;
		}
		&__ul {
			display: none;
		}
	}
}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
