




































































































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

.LuMainMenu {
	//background-color: fade( yellow, 75 );

	&__row {
		display: flex;
	}
	&__li {
		position: relative;
		list-style: none;
		display: flex;
	}
	&__footerLinks {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	// this fold toggle button disables the click on the events/shop-links
	// die auf dem desktop getriggert werden
	&__mobileListToggleBtn {
		//background-color: fade( red, 20 );

		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 2;
		cursor: pointer;
	}

	&__a {
		position: relative;
		display: flex;
		flex-grow: 1;
		text-decoration: none;
		align-items: center;
		color: inherit;

		// indent second level links
		&--second {
			padding-left: 1em;
		}

		// active indicator
		&--first::before {
			//background-color: fade(red, 60);
			transition: all 0.4s ease;

			position: absolute;
			left: -1em;
			//transform: scale(0);
			transform-origin: center;

			//opacity: 0;
			font-size: 1.25em;
			content: "•";
		}
		&--first.routerLink--exactActive::before {
			left: -0.75em;
			//opacity: 1;
		}
		&--second::before {
			transition: all 0.35s ease;

			position: absolute;
			left: 0;
			left: -2em;
			//transform: scale(0);
			transform-origin: center;

			//opacity: 0;
			font-size: 1.25em;
			content: "•";
		}
		&--second.routerLink--exactActive::before {
			//transform: scale(1);
			left: 0;
			//opacity: 1;
		}
	}
	&__a--footerLink {
		flex-grow: 0;
	}
	&__countProducts {	// hochgestellter counter hinter den filtern unter shop
		display: inline-block;
		padding-left: .5em;
		transform: translateY(-.25em);

		font-size: .7em;
		line-height: 1em;
		font-weight: 400;
	}
}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
