




















































































































































































































.mhVibrant {
	z-index: 100;

	&__palette {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		line-height: 1.2em;
		color: black;
	}
	&__swatch {
		padding: 0.1em;
		height: 1.5em; width: 32%;
		margin-bottom: 2%;
	}
	&__results {
		color: white;
	}
}
