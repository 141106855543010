


































































@import "../../less/vars.less";
@import "../../less/mixins.less";
@import (reference) "../../less/atoms.less";

.mhSearchForm {
	width: 100%;
	display: flex;
	//overflow: hidden;

	&__input {
		background-color: transparent;
		transition: opacity 0.1s ease;
		flex-grow: 1;

		line-height: 1em;
		outline: none;
		border: none;
		color: inherit;
		opacity: 0.75;

		&::placeholder {
			transition: transform 0.4s ease;
			opacity: 0.5;
			//color: fade( black, 75 );
			color: currentColor;
			transform: translateX( 0% );
		}
		&:focus {
			outline: none;
			opacity: 1;

			&::placeholder {
				transform: translateX( -50% );
				//opacity: 0.3;
			}
		}
	}
	&__btn {
		//background-color: fade(red, 30) !important;

		.reset;
		.reset--button;
		.icon;
		.icon--lense;
	}
}
