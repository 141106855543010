



















































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuProductTeasers {
	position: relative;

	&__inner {}

	&__product {
		[showborders3] & { background-color: fade(red, 30);	}

		position: relative;
		display: flex;
		flex-direction: column;
		transform: translateZ( 1px ); // solves some blurry/color changing issues

		&Image {
			[showborders3] & { background-color: fade(red, 10); }

			transition: all 0.3s ease 0.1s;
			padding-bottom: 100%;
			margin-right: 0.75rem; // prevent to small gaps

			//display: none;
		}
		&Image--size {	// es gibt fünf verschiedene product größen
			.sizeMixin( @marginTop : 0rem ){
				//outline: 1px solid red;

				.mhImage__image {
					top: @marginTop;
					height: calc( 100% - @marginTop );
				}
			}

			&-huge { 	.sizeMixin( 0rem ); }
			&-big {		.sizeMixin( 1rem ); }
			&-default { .sizeMixin( 2rem ); }
			&-small { 	.sizeMixin( 2.5rem ); }
			&-mini { 	.sizeMixin( 3rem ); }
		}
		&:hover &Image {
			transition-duration: 0.2s;
			transition-delay: 0s;
			transform: translateY( -0.75em );
		}

		&TextInfos {
			flex-grow: 1;
			margin-top: 0.75em;
		}
		&Link {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			// hide "Read more ..."
			font-size: 0; line-height: 0;
			z-index: 5;
		}
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
// enlarge books on mobile
@media @mediaQuery__md {
	.LuProductTeasers {
		&__productImage {
			padding-bottom: 130%;
		}
	}
}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
