
































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuPageHeader {
	position: relative;

	&__inner {}

	&__title {
		span {
			display: inline-block;
			transform: translateY(0.1em);
		}
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
