





















































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuEventsWidget {
	position: relative;

	&__location:empty {
		display: none;
	}
	&__location {
		padding-top: 0.2em;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
