








































































































































.MhDevGrid {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0; // span the wrapper
	pointer-events: none; 
	display: flex;
	justify-content: center;
	
	z-index: 100;
	
	&__cols {
		//position: absolute;
		//top: 0; left: 0; right: 0; bottom: 0; // span the wrapper
		display: grid;
		//grid-auto-flow: column;
		//max-width: 1200px;
		width: 100%;
	}
	&__col {
		// just for colum-index text styling
		text-align: right;
		line-height: 1em;
		padding: 0.25em;
		min-height: 0;
		min-width: 0;
	}
}
