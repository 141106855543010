


























































































































































































































































































































































































































































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

@tilLine__stroke        : 0.1em;
@tilLine__width         : 0.75em;
@tilLine__expandedWidth : 4em;
@tilLine__margin        : 0 0.15em 0 0.03em;

.LuDate {
	display: flex;
	position: relative;

	//& > * { outline: 1px solid red; }
	//&__tilDivider { background-color: fade( red, 20 ); }

	// dotDivider
	&__dotDivider {
		padding-right: 0.05em;
	}

	// line between dates
	&__tilDivider {
		position: relative;
		width: @tilLine__width;
		display: flex;
		margin: @tilLine__margin;
		display: inline-block;
	}
	&__tilDividerStroke {
		position: absolute;
		top: 50%; left: 0; right: 0;
		transform: translateY( -40% );
		display: block;

		height: @tilLine__stroke;
		background-color: currentColor;
	}

	// labels ( "days left" and "days to to")
	&__daysLeftLabel,
	&__daysToGoLabel {
		position: relative;
		background-color: currentColor;

		padding-top: 0.1em;
		padding-left: 0.4em;
		padding-right: 0.4em;
		height: 1.2em;

		white-space: nowrap;
		line-height: 1em;
		font-size: 0.385em;
		display: none;

		&::before { color: white; }
	}
	&__daysLeftLabel {
		position: absolute;
		top: 0; left: 50%;
		transform: translateX( -50% );

		&::before { content: attr( data-daysLeft ) " days left"; }
	}
	&__daysToGoLabel {
		position: relative;
		top: 0.6em;
		top: 0;
		left: 0.5em;

		&::before { content: attr( data-daysToGo ) " days to go"; }
	}

	// is running
	&--showDaysCounter&--isRunning {
		//color: red;

		.LuDate__tilDivider {
			width: @tilLine__expandedWidth;
		}
		.LuDate__tilDividerStroke::before {
			position: absolute;
			top: 0; left: 0; bottom: 0; right: 50%;
			content: "";
			background-color: fade( white, 75 );
		}
		.LuDate__daysLeftLabel {
			display: block;
		}

	}
	// will start
	&--showDaysCounter&--startsInFuture {
		//color: blue;

		.LuDate__daysToGoLabel {
			display: block;
		}
	}
}
