






































































































































































































































































































.mhImage {
	position: relative;
}
.mhImage__debugInfos {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	overflow: auto;
	
	// label with debug infos
	[showborders4] &:before {
		position: absolute;
		top: 0; left: 0;
		content: attr(data-elmWidth) " × " attr(data-elmHeight) "\a" "checkBy: " attr(data-checkSizeBy) "\a" "savePixel: " attr(data-savePixel) "\a" "bestSize: " attr(data-bestSizeName) ;
		padding: 0.3em;
		
		font-family: sans-serif;
		white-space: pre-line;
		color: fade(black, 75);
		font-size: 11px;
		line-height: 1.2em;
		background-color: yellow;
	}
	
	pre {
		background-color: fade(yellow, 50);			
	}
}

.mhImage__imageWrapper {
	//background-color: fade( yellow, 50 );
	
	position: relative;
	width: 100%;
}
.mhImage__imageWrapper--mode-cover {
	position: absolute;
	top: 0; bottom: 0;
}	
.mhImage__imageWrapper--mode-contain {
	position: absolute;
	top: 0; bottom: 0;
}	

.mhImage__image {
	position: absolute;
	top: 0; left: 0;
	height: 100%;
	width: 100%;
}
.mhImage__image--mode-img {
	object-fit: cover;
}
.mhImage__image--mode-cover {
	object-fit: cover;
}
.mhImage__image--mode-contain {
	object-fit: contain;
}
