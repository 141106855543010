

























































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuPrice {
	position: relative;
	white-space: nowrap;

	&__inner {
		//display: inherit;
	}

	&__centDivider,
	&__groupDivider,
	&__currencyString {
		display: inline-block;
		text-align: center;
	}
	&__centDivider {
		[showborders5] & { .outline( red ) };
		margin: 0 -0.08em;
	}
	&__groupDivider {
		[showborders5] & { .outline( blue ) };
		margin: 0 -0.08em;
	}
	&__currencyString {
		[showborders5] & { .outline( green ) };
		margin-left: -0.25em;
	}
}
