














































































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

// vars
:root {
	--swatch__white              : @swatches[white];
	--swatch__textWhite          : @swatches[textWhite];
	--swatch__textBlack          : @swatches[textBlack];
	--swatch__awardColor         : @swatches[awardColor];
	--swatch__shopBackground     : @swatches[shopBackground];
	--swatch__mario              : @swatches[awardColor];

	--duration__tint             : 0s;
	--appLoadingSpinner__opacity : 0;
}
// map vars to properties
:root {
	--appBody__background      : var(--swatch__white);
	--appBody__color           : var(--swatch__textBlack);

	--appBodyInner__background : var(--swatch__white);
	--appBodyInner__color      : var(--swatch__textBlack);

	--appNav__background       : var(--swatch__white);
	--appNav__color            : var(--swatch__awardColor);

	--appMobileNav__background : var(--swatch__white);
	--appMobileNav__color      : var(--swatch__awardColor);
}
// map properties to selectors
.app {
	.app__nav,
	.app__mobileNav,
	.app__bodyInner {
		transition:
			background-color var(--duration__tint) ease,
			color 			 var(--duration__tint) ease;
	}
	.app__bodyInner {
		transition:
			opacity 		 var(--duration__tint) ease;
	}

	.app__nav {
		background-color: var(--appNav__background);
		color: var(--appNav__color);
	}
	.app__mobileNav {
		background-color: var(--appMobileNav__background);
		color: var(--appMobileNav__color);
	}
	.app__body {
		background-color: var(--appBody__background);
		color: var(--appBody__color);
	}
	.app__bodyInner {
		background-color: var(--appBodyInner__background);
		color: var(--appBodyInner__color);

		//@transitionDelay : 0s;

		// router view transition
		&.fade-enter-active,
		&.fade-leave-active {
			transition-duration: var(--duration__tint);
			transition-property: opacity;
			transition-timing-function: ease;
		}
		&.fade-enter-active {
			//transition-delay: var(--duration__tint) !important;
		}
		&.fade-enter,
		&.fade-leave-active {
			opacity: 0;
			//transition-delay: @transitionDelay !important;
		}
	}
	.app__loadingSpinner {
		opacity: var(--appLoadingSpinner__opacity);
		//opacity: 1 !important;
	}

}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
