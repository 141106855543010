












































































































































































@import "../../less/vars.less";
@import "../../less/mixins.less";
@import (reference) "../../less/atoms.less";

@transitionDuration : 0.2s;	// for router-view change animation
@transitionDelay    : 0.1s;	// for router-view change animation
// delay ist totaler quatsch, das macht timing stress
@transitionDelay    : 0s;	// for router-view change animation
@transitionDuration : 0s;	// for router-view change animation

.MhView {
	position: relative;
	//background-color: fade( red, 20 );
	min-height: 100vh;

	&__loadingSpinner {
		transition-duration: @transitionDuration * 0.5; // make it faster appear/disappear
		transition: opacity @transitionDuration ease-in-out;

		position: fixed;
		transform: translateY(-50%) translateX(-50%);
		pointer-events: none;
		opacity: 0;
		z-index: 2;
	}
	&__inner {
		transition: opacity @transitionDuration ease;
		opacity: 0;

		//position: absolute; top: 0; left: 0; right: 0; bottom: 0;
		//width: inherit;
		//overflow: hidden;

		// muss scroll sein damit die scrollbar angezeigt wird und die restlichen layout berechnungen stimmen
		// overflow-y: auto;
		//overflow-y: scroll;
		//-webkit-overflow-scrolling: touch; // enable smooth scrolling on iOS
		//z-index: 1;	 // wozu der z-index?
		//display: none;
	}

	// handle states
	&--state-loading {
		.MhView__inner {
			//opacity: 0;
			pointer-events: none;
		}
		.MhView__loadingSpinner {
			opacity: 1;
		}
	}
	&--state-loaded {
		.MhView__inner {
			opacity: 1;
		}
		.MhView__loadingSpinner {
			//opacity: 1 !important; // just for debug
			opacity: 0;
		}
	}

	// router view transition
	.fade-enter-active,
	.fade-leave-active {
		transition-duration: @transitionDuration;
		transition-property: opacity;
		transition-timing-function: ease;
	}
	.fade-enter-active {
		transition-delay: @transitionDelay !important;
	}
	.fade-enter,
	.fade-leave-active {
		opacity: 0;
		transition-delay: @transitionDelay !important;
	}
}

