











































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuHeadline {
	position: relative;

	&__anchor {
		position: absolute;
		top: -8.5rem;
		pointer-events: none;
		visibility: hidden;
	}
	&__link {
		//background-color: fade(green, 10);

		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		overflow: hidden;
		text-indent: -100vw;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
