




























































































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuNewsletterWidget {
	position: relative;

	&__form {
		//background-color: fade( red, 20 );
		//display: flex;
		margin-bottom: 0.35em;
	}
	&__submitMessage {
		position: absolute;
		top: 0; left: 0; width: 100%; height: 100%;
		background-color: white;
		opacity: 0;
		transition: all 0.2s ease;
		padding-right: 1em;
		pointer-events: none;
	}
	&__submitMessage--isVisible {
		opacity: 1;
		pointer-events: auto;
	}

	.BaseInput {
		flex-grow: 1;

		&__input { 	width: 100%; }
	}
	.BaseInput--invalid .BaseInput__input:not(:focus) {
		background-color: fade( red, 35 );
	}

	.LuEventsWidget + & {
		margin-top: -@border[strongWidth];
	}

}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
