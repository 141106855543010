

















































































@import "../../less/vars.less";
@import "../../less/mixins.less";

.BaseButton {
	position: relative;

	transition: all 0.05s ease;

	line-height: 1em;
	text-overflow: ellipsis;
	border-radius: 2px;
	max-width: 100%;
	display: inline-block;
	overflow: hidden; // needed for the border-radius

	&:active {
		//.shadow--none;
		transform: translateY(0.1rem);
	}

	button {
		background-color: transparent;
		outline: none;
		color: inherit;
		white-space: nowrap;
		overflow: hidden;
		border: 0px solid;
	}

	&__inner {
		transition: inherit;
		padding: 0.75rem 0.9rem;
		font-weight: inherit;
		color: inherit;
	}
	&__border {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		pointer-events: none;
	}
}
.BaseButton--addToCart {
	display: inline-flex;
	background-color: currentColor;
	outline: none;
	font-weight: bold;

	.BaseButton__inner {
		text-align: center;
		//width: 100%;
		color: @swatches[shopBackground];
	}
	.BaseButton__border {}
}
.BaseButton--removeFromCart {
	 //color: black !important;

	 height: 1.1em; width: 1.1em;
	 padding: 0;
	 margin: 0;
	 overflow: hidden;
	 transform: translateY(2px);
	 justify-content: center;
	 text-align: center;

	 transition: opacity 0.1s ease;
	 opacity: 0.5;
	 &:hover { opacity: 1; }

	 .BaseButton__inner {
		 //background-color: blue;
		 //color: red;
		 display: inline;
		 line-height: 1em;
		 text-align: center;
		 transform: translateY(0px);
		 font-size: 1em;
		 padding: 0;
	 }
	 .BaseButton__border {
		 border-radius: 50%;
		 border: 1px solid @swatches[shopBackground];
	 }
}
.BaseButton--cartNav {
	display: inline-flex;

	.BaseButton__inner {
		display: flex;
		flex-grow: 1;
	}
	.BaseButton__border {
		border: 1px dotted @swatches[shopBackground];
	}
	&:hover .BaseButton__border {
		border-style: solid;
	}
}
.BaseButton--cartNav.BaseButton--cta {
	background-color: lighten( @swatches[shopBackground], 5 );
	font-weight: bold;
	outline: none;

	.BaseButton__inner {
		color: white;
	}
	.BaseButton__border {
		border: 3px solid lighten( @swatches[shopBackground], 5 );
	}

	&:hover {
		background-color: black;

		.BaseButton__border {
			border-color: black;
		}
	}
	&:active {
		transform: translateY(0.15em);
	}
}
.BaseButton--cartNav.BaseButton--disabled {
	opacity: 0.5;
	pointer-events: none;
}

.BaseButton--newsletterAbo {
	background-color: lighten( @swatches[shopBackground], 5 );
	display: inline-flex;
	font-weight: bold;
	outline: none;
	height: 2em;

	.BaseButton__inner {
		display: flex;
		flex-grow: 1;
		color: white;
		padding: 0.5rem 0.7rem;
		height: inherit;
	}
	.BaseButton__border {
		border: 3px solid lighten( @swatches[shopBackground], 5 );
	}
	&:hover {
		background-color: black;

		.BaseButton__border {
			border: 3px solid black;
		}
	}
	&:active {
		transform: translateY(0.15em);
	}
}
.BaseButton--loading {
	//opacity: 0.5;
	overflow: hidden;

	.BaseButton__inner {
		transform: translateY(100%);
	}

	.BaseButton__inner::before {
		position: absolute;
		top: 50%; left: 50%;
		content: "";
		height: 1em; width: 1em;
		transform: translateX(-50%) translateY(-250%);
		animation: BaseButtonSpin 1s linear infinite;

		clip-path: polygon(50% 0%, 50% 50%, 100% 50%, 100% 100%, 0 100%, 0 0);
		border: 2px solid currentColor;
		border-radius: 50%;
		//border-left-color: black;
	}

	@keyframes BaseButtonSpin {
		0% { 	transform: translateX(-50%) translateY(-250%); }
		100% { 	transform: translateX(-50%) translateY(-250%) rotate(360deg); }
	}
}
