
















































































































































































































































































@import "../../less/vars.less";
@import "../../less/mixins.less";
@import (reference) "../../less/atoms.less";

.MhSmartHeader {
	//background-color: fade(red, 20);

	position: relative;
	z-index: 10;
}
.MhSmartHeader__rectHelper {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	pointer-events: none;
}
.MhSmartHeader__inner {
	//background-color: fade(yellow, 60);
	z-index: 1;
	transform: translateZ(1px); // iOS needs this to render the bar above the book teasers
}
.MhSmartHeader__debug {
	display: none;
	background-color: fade(red, 80);

	position: fixed;
	bottom: 2rem; left: 1rem;
	z-index: 100;
	padding: 0.25em;
	font-size: 12px;
	line-height: 15px;
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
